import React from "react";
import styled from "styled-components";
// import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { Section, Title, Text, Box, Button, Span } from "../components/Core";
import { device } from "../utils";
import { langKeyDefault } from "../../buildVariables";
import { FormattedMessage } from "react-intl";

const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
  .active {
    color: ${({ theme }) => theme.colors.secondary} !important;
  }
`;

const StyledCTAButton = styled(Button)`
  padding: 12px 18px;
  white-space: normal;
  transform: none;
  @media ${device.md} {
    padding: 0.85rem 1.75rem;
  }
`;

export const FaqPageTemplate = ({
  title,
  subtitle,
  categories,
  noanswer,
  tutorials,
  or,
  contact,
  langKey,
}) => {
  return (
    <section className="section">
      <Section className="pb-0">
        <div className="pt-5 pt-lg-0"></div>
        <Container>
          <Row className="justify-content-md-center text-md-center">
            <Col lg="12">
              <Title>{title}</Title>
              <Box>
                <Text mr={3}>{subtitle}</Text>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
      <Container className="pt-5 pb-5">
        <Section bg="ash" py="32px" px={4} style={{ borderRadius: 10 }}>
          <Row>
            <Col lg="6">
              <div>
                <Title
                  variant="card"
                  size="40px"
                  color="light"
                  className="mb-0"
                >
                  <FormattedMessage
                    id="faq.new1"
                    defaultMessage="Our new help center is now available"
                  />
                </Title>
                <Text
                  fontSize="18px"
                  as="h4"
                  color="lightShade"
                  className="mb-0"
                >
                  <FormattedMessage
                    id="faq.new2"
                    defaultMessage="Comprehensive instructions, videos, and many more answers"
                  />
                </Text>
              </div>
            </Col>
            <Col
              lg="6"
              className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0"
            >
              <div>
                <a
                  href={
                    "https://help.tournifyapp.com/" +
                    (langKey === "pl" ? "" : langKey)
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={
                    <FormattedMessage
                      id="faq.visitHelpCenter"
                      defaultMessage="To the new help center"
                    />
                  }
                >
                  <StyledCTAButton
                    bg="white"
                    color="dark"
                    px={["0.75rem", "1.75rem"]}
                  >
                    <FormattedMessage
                      id="faq.visitHelpCenter"
                      defaultMessage="To the new help center"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      css={`
                        fill: currentcolor;
                        vertical-align: middle;
                        height: 21px;
                        width: 21px;
                        margin-left: 10px;
                      `}
                    >
                      <path d="M384 32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H384zM160 144c-13.3 0-24 10.7-24 24s10.7 24 24 24h94.1L119 327c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l135-135V328c0 13.3 10.7 24 24 24s24-10.7 24-24V168c0-13.3-10.7-24-24-24H160z" />
                    </svg>
                  </StyledCTAButton>
                </a>
              </div>
            </Col>
          </Row>
        </Section>
      </Container>

      <Container>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={categories[0].name}
        >
          <Row>
            <Col md="4" className="mb-5 mb-md-0">
              <NavStyled className="flex-column mr-md-5">
                {categories.map((category) => (
                  <Nav.Link key={category.name} eventKey={category.name}>
                    {category.name}
                  </Nav.Link>
                ))}
              </NavStyled>
            </Col>
            <Col md="8">
              <Tab.Content>
                {categories.map((category) => (
                  <Tab.Pane key={category.name} eventKey={category.name}>
                    <Box>
                      {category.faq.map((faq) => (
                        <Box mb={4} key={faq.question}>
                          <Title variant="card" mb={2} fontSize="24px">
                            {faq.question}
                          </Title>
                          <Text variant="small">{faq.answer}</Text>
                        </Box>
                      ))}
                      <Box mb={4}>
                        {noanswer + " "}
                        <a
                          href={
                            "https://youtube.com/playlist?list=PLZIpNugyudAdPS0Rk3S6g1pOe_RbyJnnH"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <Span color="primary">{tutorials}</Span>
                        </a>{" "}
                        {or}{" "}
                        <Link
                          to={
                            "/" +
                            (langKey === langKeyDefault ? "" : langKey + "/") +
                            "contact"
                          }
                        >
                          <Span color="primary">{contact}</Span>
                        </Link>
                        {"."}
                      </Box>
                    </Box>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </section>
  );
};

// TermsPageTemplate.propTypes = {
//   title: PropTypes.string.isRequired,
//   content: PropTypes.string,
//   contentComponent: PropTypes.func,
// };

const FaqPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <PageWrapper footerDark>
      <FaqPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        noanswer={post.frontmatter.noanswer}
        tutorials={post.frontmatter.tutorials}
        or={post.frontmatter.or}
        contact={post.frontmatter.contact}
        categories={post.frontmatter.categories}
        langKey={post.fields.langKey}
      />
    </PageWrapper>
  );
};

// TermsPage.propTypes = {
//   data: PropTypes.object.isRequired,
// };

export default FaqPage;

export const FaqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        langKey
      }
      frontmatter {
        title
        subtitle
        noanswer
        tutorials
        or
        contact
        categories {
          name
          faq {
            question
            answer
          }
        }
      }
    }
  }
`;
